
<template>
  <div class="image-block">
    <ResponsiveImage
      v-if="mobileImage.filename || desktopImage.filename"
      :mobile-src="mobileImage.filename"
      :desktop-src="desktopImage.filename"
      :alt="mobileImage.alt ? mobileImage.alt : ''"
      :mobile-display-size="mobileDisplaySize"
      :desktop-display-size="desktopDisplaySize"
      :breakpoint="breakpoint"
      :lazyload="true"
    />
    <mp-link
      v-if="$u(link) !== null"
      :to="$u(link)"
      class="link"
    />
    <template v-if="hotspots.length">
      <HotspotController
        v-for="(hotspot, index) in hotspots"
        :key="'hotspot-' + index"
        v-bind="hotspot"
      />
    </template>
  </div>
</template>

<script>
import HotspotController from '~/components/hotspots/HotspotController.vue'
import ResponsiveImage from '~/components/image/ResponsiveImage.vue'
export default {
  name: 'ImageBlock',
  components: {
    HotspotController,
    ResponsiveImage
  },
  props: {
    mobileImage: {
      type: Object,
      required: false,
      default: null
    },
    desktopImage: {
      type: Object,
      required: false,
      default: null
    },
    link: {
      type: Object,
      required: false,
      default: null
    },
    /**
     * Image display sizes for optimized images
     * Can be sent from a parent, ie the grid component
     * Defaults to 100vw if not sent
     */
    mobileDisplaySize: {
      type: String,
      required: false,
      default: '100vw'
    },
    desktopDisplaySize: {
      type: String,
      required: false,
      default: '100vw'
    },
    hotspots: {
      type: Array,
      required: false,
      default: () => ([])
    },
    breakpoint: {
      type: Number,
      required: false,
      default: 1024
    },
  }
}
</script>

<style lang="scss" scoped>
.image-block {
  position: relative;
  &::v-deep {
    .responsive-img {
      position: relative;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .link {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
</style>
